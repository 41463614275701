import logo from './logo.svg';
import './App.css';
import { useState } from "react";
import axios from "axios";
import React from 'react';
import ReactPlayer from 'react-player';
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from 'react-share';
import { ChatGptResponse} from './chat_gpt_response.js'

export default function App() {
  const [loading, setLoading] = useState(false);
  let [gptOutput, setGptOutput] = useState("");
  const [payload, setPayLoad] = useState({
    prompt: "Hi, how are you?",

    temperature: 0.5,
    n: 1,
    model: "text-davinci-003",
    max_tokens:50
  });

const reply_context = "You are Lord Rama from Ramayana, and you're here to selflessly help and answer any question or dilemma of anyone who comes to you. Keep the response in less than 50 words: "

  const getRes = () => {
    const data_axios = { ...payload, prompt: reply_context + "$" + payload["prompt"] +"$"}
    setLoading(true);
    axios({
      method: "POST",
      url: "https://api.openai.com/v1/completions",
      data: data_axios,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          //"Bearer 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"
          "Bearer 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"        
      }
    })
      .then((res) => {
        console.log(res);
        responseHandler(res);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e.message, e);
      });
  };
  
  const responseHandler = (res) => {
    if (res.status === 200) {
      console.log("gpt output");
      console.log(res.data);
      console.log(res.data["choices"][0]["text"]);

      const gptOut = res.data["choices"][0]["text"].replace(/\n|\r/g, "");
      setGptOutput(gptOut);
      setLoading(false);
    }  
  };
  
  return (  
    <div className="App">
      <div className="container">
        <div className="d-flex">
          <div className="col-6 text_wrap">
            <textarea
              type="text"
              placeholder="Enter Text"
              readOnly={loading}
              onChange={(e) => {
                setPayLoad({
                  ...payload,
                  prompt: e.target.value
                });
              }}
              value={payload.prompt}
            />
          </div>
        </div>
        <div style={{ padding: "0 13px" }}>
          <button disabled={loading} onClick={getRes}>
            {loading ? "Loading... " : "Get response"}
          </button>
        </div>
          <div className="col-6 text_wrap">
            <p>
              {loading ? (
                <span>loading...</span>
              ) : (
                  <ChatGptResponse response={gptOutput}/>
              )}
            </p>
          </div>
      </div>
          
          <TwitterShareButton
            url={'https://www.example.com'}
            quote={'Dummy text!'}
            hashtag="#muo"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <FacebookShareButton
          url={'https://www.example.com'}
          quote={'Dummy text!'}
          hashtag="#muo"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

    </div>
  );
}

