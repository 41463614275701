import React from 'react';
import ReactPlayer from 'react-player';
import { useState, useEffect } from "react";

export function Movio(props) {
  const [videoId, setVideoId] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
     generateVideo(props.chatGptRes)

  }, []);

  // Todo(nikhil): Add the list of api keys here.
  
  const apiKeyArray = [ 
    ["NTVkMTMyZjc0MWUyNGI1Zjk3NjdiYjEyMWVkNGExZTQtMTY3NzkxNDk5Ng==", "6d864a9cb29043c6999043ee09be1117"],
    //["ZGVkYWM1MDU0Y2YxNDI5MTkyYjM0MWVhOTA3OTUwNDQtMTY3OTQ2MTEyMA==", "943dd52bf7564db38838e3d6483bb2c2"],
    //["NjE4MDYwMDAxOWRiNGVkNmFlYTU2YjZkMGY3ZWM4N2YtMTY3OTQ2MzE0NA==", "499d07eed85f44f6af62b2d65369d335"],
    //["ZjIwZmU5ZDE3MWNjNDdhOWI0MmQ1ZmVlMTdlOTVkMzAtMTY3OTQ2MzU4NQ==", "a8a9177f8ede4abcaed0892f094ae24f"],
    //["OGNlZGNiZDE2NjBiNDlhZjkwZGUxZjRlODk3MDFjYTYtMTY3OTQ2NDAwMg==", "e028c0d405034a7a838a1ac008ea9877"],
    //["NWVkZWZkYTcwNTQzNDgyM2E3MWZhYmFhODBiYzRkMjMtMTY4MDY3MTg1Nw==", "a472202cef3244f4a05c83e9eb12d8dc"],
    //["MzA4ZmRiM2Q2ZjUxNGI2MGE3ZDc1NzU4ODk2ZmIyYTMtMTY4MDY3MjQxNw==", "d5fd1efef50b4291b33543489443a4c9"],
    //["ZmE4OGI0ODdjMDY4NDM2OWIyZDQ4MTlhODM3ZDM3YTItMTY4MDY3Mjc0NA==", "11103badf89c4c2f99622148ba7a8273"],
    ["MGRiYjMzYWViYjhhNGFjOWE1MWI5ZjUzNzZhZDI3N2ItMTY4MDY3Mjg3MA==", "0856f2333d984ab085b9bf48c39edb3d"],
    ["MGQyMzdjMWIwOWVjNDE0Njk0MDEyYmJjYjU2YTU5MzktMTY4MDY3MzU3MQ==", "7fe7dea6e1584b8ab548d1bf0e47fa3b"]
  ]
  
  let currentIndex = 0;
  const apiKey =
    "NTVkMTMyZjc0MWUyNGI1Zjk3NjdiYjEyMWVkNGExZTQtMTY3NzkxNDk5Ng==";

  const generateVideo = (gptOutput) => {
    const url = "https://api.movio.la/v1/video.generate";
    
    const payload = {
      background: "#ffffff",
      clips: [
        {
          avatar_style: "normal",
          caption: false,
          input_text: gptOutput,
          scale: 1,
          voice_id: "ef5765a5c2ee49e58f7dd942e67fb6f2",
          talking_photo_style: "normal",
          //talking_photo_id: "6d864a9cb29043c6999043ee09be1117",
          talking_photo_id: apiKeyArray[currentIndex][1],
        },
      ],
      ratio: "16:9",
      test: true,
      version: "v1alpha",
    };

    console.log("Creating video payload");
    console.log(payload);

    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "application/json",
        "x-api-key": apiKeyArray[currentIndex][0],
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        console.log("In response section");
        console.log(res);
        currentIndex = currentIndex + 1;
        if(res.status === 429 && currentIndex < apiKeyArray.length ) {
          console.log("I will call again");
          return generateVideo(gptOutput);
        }
        return res.json();
      }
      )
      .then((data) => {
          console.log("in data section");
          console.log(data);
          const videoId = data && data["data"] ? data["data"]["video_id"] : ""
          if(videoId) {
            setVideoId(data["data"]["video_id"]);
            fetchStatus(videoId);
          }  
        }
      )
      .catch((error) => {
            console.error("What is the error?");
            console.error(error);
          }
        );
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const fetchStatus = async (videoIdParam) => {
    const url = `https://api.movio.la/v1/video_status.get?video_id=${videoIdParam}`;
    const headers = { "X-Api-Key": apiKeyArray[currentIndex - 1][0] };
    const response = await fetch(url, { headers });
    console.log("Fetch status api response")
    console.log(response)
    if(response.status === 200) {
      const data = await response.json();
      const status = data["data"]["status"]
      if ( status && status === "completed") {
        setVideoUrl(data["data"]["video_url"]);
      } else {
        console.log("sleeping")
        await delay(10000);
        fetchStatus(videoIdParam);
      }
    }
    else {
      console.log("sleeping")
      await delay(10000);
      fetchStatus(videoIdParam);
    }
  }

  // const { data, error, isLoading } = useSWR('video_fetcher', generateVideo(props.chatGptRes))
  // if (error) return <div>failed to load</div>
  // if (isLoading) return <div>loading...</div>

  return (
    <div>
      {/* <ReactPlayer url={videoUrl} playing={true}
                controls={true}
                loop={true}/>  */}
                
      <ReactPlayer 
        url={videoUrl} 
        playing={true}
        controls={true}
        loop={true}
      />
    </div>
  );
}