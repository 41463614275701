import { Movio } from './movio.js'
export function ChatGptResponse(props) {
    if (props.response === null || props.response.trim() === "") {
        return
    }

 return (
    <div>
        <div>{props.response}</div>
        <Movio chatGptRes={props.response} />
    </div>
 );
}